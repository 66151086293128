import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Icon, { RightArrowNoBorder } from "../Icons";
import { onLoadScrollHandler } from "../../util/helpers";

const CtaTile = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const ctas = props.data.ctas && props.data.ctas.length > 0 && props.data.ctas.map( (cta: any, index: any) => 
    (
      <div key={index} className={'animate slide-from-top ease-in-out delay-'+((index+1)*250)+' border-2 border-green rounded-full w-50 h-50 md:w-75 md:h-75 px-3.5 md:px-5 pt-12.5 md:pt-22 mb-8 last:mb-0 md:mb-0 mx-auto md:mx-0 text-center md:ml-20 md:first:ml-0'}>
        <Link to={'/'+cta.link_url.post_name} className="font-bold text-sm md:text-lg block px-1">
          <span className="text-white block mb-3.5 md:mb-6">{ cta.title }</span>
          <span className="block text-green mb-1">{ cta.link_text }</span>
          <Icon type={<RightArrowNoBorder />} />
        </Link>
      </div>
    )
  );

  return (
    <section ref={sectionEl} className="py-20 md:py-56 relative" style={{
      background: `url('${props.data.background_image[0]}') center no-repeat`,
      backgroundSize: 'cover'
    }}>
      <div className="w-full h-full absolute top-0 left-0 bg-black opacity-65" />
      <div className="mx-auto w-full max-w-7xl px-5 md:flex justify-center relative z-2">
        { ctas }
      </div>
    </section>
  );
}

export default CtaTile;