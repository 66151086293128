import { useEffect, useState } from "react";
import axios from "axios";
import { Routes, Route, useLocation } from 'react-router-dom';

import Page from "../pages/Page";
import ThankYou from "../pages/ThankYou";
import NotFound from "../pages/NotFound";

const apiUrl = process.env.REACT_APP_API_URL;

const AppRoutes = () => {
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    getMenus();
  }, []);

  const getMenus = () => {
    axios.get(`${apiUrl}/pages/get`)
    .then( response =>  {
      setMenuItems(response.data);
    })
    .catch( error => {
      console.error(error);
    });
  }

  const routeList = menuItems && menuItems.length > 0 && menuItems.map( (route: any, index: any) => 
    (
      <Route key={index} path={'/'+route.slug} element={<Page slug={route.slug} title={route.title} />} />
    )
  );

  const routePaths = menuItems && menuItems.length > 0 && menuItems.map((route: any) => route.slug);

  return (
    <Routes>
      <Route path="/" element={<Page slug="home" title="Home" />} />
      { routeList }
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="*" element={<NotFound paths={routePaths || [location.pathname]} />} />
    </Routes>
  );
}

export default AppRoutes;