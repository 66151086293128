import React from "react";

interface IProps {
  color?: string;
}

const IconMail: React.FC<IProps> = ({
  color = '#83f1aa'
}) => {
  return (
    <svg id="Email_Icon" data-name="Email Icon" xmlns="http://www.w3.org/2000/svg" width="41.794" height="32" viewBox="0 0 41.794 32">
      <g id="Group_26" data-name="Group 26">
        <path id="Path_53" data-name="Path 53" d="M41.794,4.551a4.509,4.509,0,0,0-1.153-3c-.009-.014-.01-.029-.019-.042s-.043-.035-.061-.056A4.529,4.529,0,0,0,37.242,0H4.552A4.556,4.556,0,0,0,0,4.551v22.9A4.557,4.557,0,0,0,4.552,32h32.69a4.531,4.531,0,0,0,3.273-1.4c.007-.007.017-.009.024-.016s.024-.043.04-.062a4.521,4.521,0,0,0,1.215-3.072ZM25,14.106,39.681,3.327a2.7,2.7,0,0,1,.3,1.224v22.9a2.722,2.722,0,0,1-.231,1.095ZM37.242,1.81a2.715,2.715,0,0,1,1.115.243L20.909,14.868,3.926,1.889a2.745,2.745,0,0,1,.626-.079ZM2.085,28.622a2.714,2.714,0,0,1-.275-1.175V4.551a2.72,2.72,0,0,1,.523-1.6L16.919,14.1Zm2.467,1.567a2.7,2.7,0,0,1-1.189-.283L18.374,15.212l1.973,1.508a.909.909,0,0,0,1.087.009l2.09-1.536,14.987,14.67a2.709,2.709,0,0,1-1.269.326Z" fill={color}/>
      </g>
    </svg>
  );
};

export default IconMail;
