import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import GlobalContext from "../store/GlobalContext";
import Icon, { Facebook, Instagram, LinkedIn } from "./Icons";
// import { triggerScroll } from "../util/helpers";
const year = new Date().getFullYear();

const Footer = () => {
  const globalCtx = useContext(GlobalContext);
  const footer = globalCtx.global.footer;
  // const sectionEl = useRef<HTMLElement>(null);

  /* const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    triggerScroll();
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []); */

  const socialIcons = footer && footer.social && footer.social.length > 0 && footer.social.map( (social: any, index: any) => 
    (
      social.icon === 'facebook' ?
      <li key={index} className="inline-block ml-12 lg:ml-5 first:ml-0">
        <a href={social.link} target="_blank" rel="noreferrer">
          <Icon className="w-4" type={<Facebook />} />
        </a>
      </li>
      
      : social.icon === 'instagram' ?
      <li key={index} className="inline-block ml-12 lg:ml-5 first:ml-0">
        <a href={social.link} target="_blank" rel="noreferrer">
          <Icon className="w-8" type={<Instagram />} />
        </a>
      </li>
      
      : social.icon === 'linkedin' ?
      <li key={index} className="inline-block ml-12 lg:ml-5 first:ml-0">
        <a href={social.link} target="_blank" rel="noreferrer">
          <Icon className="w-8" type={<LinkedIn />} />
        </a>
      </li>
      
      : null
    )
  );

  const footerMenu = footer && footer.menu && footer.menu.length > 0 && footer.menu.map( (menuItem: any, index: any) => 
    (
      menuItem.children && menuItem.children.length > 0 ?

      <div key={index} className="menu-item__group">
        <div className="menu-item__item">
        { menuItem.children.map( (submenu: any, index: any) => (
          <div key={index} className="menu-item__submenu">
            <NavLink to={'/'+submenu.slug} className={'hover:underline navlink ' + (submenu.type !== 'custom' ? 'font-bold': 'hidden md:block')}>
              { submenu.title }
            </NavLink>
          </div>
        )) }
        </div>
      </div>

      : 

      <div key={index}>{ menuItem.title }</div>
    )
  );

  return ( 
    <footer className="bg-beige px-12">
      <div className="mx-auto w-full max-w-screen-2xl pt-16 pb-7 md:pt-17 md:pb-17 text-blue">
        <div className="pb-9 md:pb-10">
          <div className="min1200px:flex min1200px:justify-between">
            <div className="md:max-w-sm text-xs md:pr-6 font-medium md:text-base">
              <Link to="/">
                <img className="w-full max-w-70 md:max-w-full mb-10" src={ footer.logo[0] } alt="Logo" />
              </Link>
              <p dangerouslySetInnerHTML={{ __html: footer.address }} className="mb-6" />
              <p>Tel. <a href={'tel:'+footer.phone}>{ footer.phone }</a></p>
              <p>E. <a href={'mailto:'+footer.email}>{ footer.email }</a></p>
            </div>
            <div className="pt-9 lg:pt-17 lg:flex">
              {
                footer && footer.social && footer.social.length && 
                <div className="mr-16 hidden lg:block">
                  <h3 className="font-bold text-base mb-6">Follow us</h3>
                  <ul>
                    { socialIcons }
                  </ul>
                </div>
              }

              <div>
                <div className="lg:grid lg:grid-cols-3 text-base leading-loose">
                { footerMenu }
                </div>
              </div>

              {
                footer && footer.social && footer.social.length && 
                <div className="mt-12 lg:hidden">
                  <h3 className="font-bold text-base mb-3">Follow us</h3>
                  <ul className="flex">
                    { socialIcons }
                  </ul>
                </div>
              }

            </div>
          </div>
        </div>
        <div className="border-t border-bordergrey pt-9 md:pt-4">
          <p className="text-xxs leading-4 font-medium text-blue">
            Abbey Ridge MIC © { year }. All rights reserved.<br />
            <span dangerouslySetInnerHTML={{ __html: footer.copyright }} />
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;