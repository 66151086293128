import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";
import Icon, { Quotes } from "../Icons";
 
const BigQuote = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }
  
  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);


  return (
    <section ref={sectionEl} className="bg-blue pb-17 content__big-quote">
      <div className="animate fade-in-grow ease-in-out w-full max-w-4xl mx-auto pl-6 pr-11 md:pl-12 md:pr-12 lg:pl-3 lg:pr-3">
        <div className="font-display italic text-green text-2xl md:text-4xl text-center relative pt-16 md:pt-14">
          <span className="block absolute top-0 left-0">
            <Icon className="w-16 md:w-29" type={<Quotes />} />
          </span>
          <span className="block relative z-2 leading-normal">{ props.data.quote }</span>
        </div>
        <div className="text-white font-sans text-xs md:text-lg font-semibold mt-7 md:mt-11 text-center">
          - { props.data.author }
        </div>
      </div>
    </section>
  );
}

export default BigQuote;