import React from "react";

interface IProps {
  color?: string;
}

const IconQuotes: React.FC<IProps> = ({
  color = '#076cff'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="116.223" height="109.914" viewBox="0 0 116.223 109.914">
      <path id="Quote_Icon" data-name="Quote Icon" d="M27.976,0,0,51.6v58.315H49.247V51.6H27.58L49.247,0ZM94.942,0,66.978,51.6v58.315h49.244V51.6H94.549L116.223,0Z" fill={color}/>
    </svg>
  );
};

export default IconQuotes;
