import { useContext, useState } from "react";
import GlobalContext from "../store/GlobalContext";
import MailChimpForm from "./Content/MailChimp/MailChimpForm";

import Icon, { CloseX } from "./Icons";

const NewsletterPopup = () => {
  const globalCtx = useContext(GlobalContext);
  const newsletter = globalCtx.global.newsletter;

  const [showPopup, setShowPopup] = useState(true);

  const closePopup = () => {
    if ( localStorage.getItem('abbeyridgeNewsletterPopup') === null ) {
      setShowPopup(false);
      localStorage.setItem('abbeyridgeNewsletterPopup', '1');
      sessionStorage.setItem('abbeyridgeNewsletterPopup', '1');
    }
    else {
      setShowPopup(false);
      localStorage.setItem('abbeyridgeNewsletterPopup', '2');
    }
  };

  // const hidePopup = () => {
  //   setShowPopup(false);
  //   sessionStorage.setItem('abbeyridge_newsletter_popup', '1');
  // }
  
  return (
    <>
    { showPopup &&
      <div className="newsletter-popup block w-screen h-screen fixed top-0 left-0 z-100 bg-black bg-opacity-75">
        <div className="bg-white max-w-[90%] md:max-w-[640px] p-5 md:pt-14 md:px-17 md:pb-14 rounded-20px fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <button className="hover:opacity-70" onClick={closePopup}>
            <Icon className="absolute top-5 right-5 w-4 h-4 md:w-8 md:h-8" type={<CloseX />} />
          </button>
          <div className="text-center lg:text-left">
            <h2 className="font-display italic text-3xl md:text-64px leading-none mt-3 mb-4.5 block text-blue">{ newsletter.heading }</h2>
            <strong className="font-semibold text-base md:font-bold md:text-2xl leading-8 mb-2 block text-blue">{ newsletter.title }</strong>
            <p className="font-medium text-xs md:text-sm leading-5 md:leading-6 mb-6 md:mb-7 max-w-2xl mx-auto lg:max-w-full text-altblue">{ newsletter.description }</p>
            <div className="max-w-full md:max-w-[458px]">
              <MailChimpForm />
            </div>
          </div>
        </div>
      </div>
    }
    </>
  )
}

export default NewsletterPopup;
