
   
import React from "react";
import Icon, { Checkmark } from "../Icons";

interface IProps {
	name: string;
	value: string;
	text?: string;
	// required?: boolean;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	onBlur: React.FocusEventHandler<HTMLInputElement>;
}

const Checkbox: React.FC<IProps> = ({ name, value, text, /* required, */ onChange, onBlur }) => {
	return (
		<label className="cursor-pointer">
			<input
				type="checkbox" 
				name={name} 
				value={value} 
				className="hidden" 
				// required={required} 
				onChange={(e) => onChange(e)} 
				onBlur={(e) => onBlur(e)} 
			/>
			<div className="flex pt-4.5 mr-2 checkmark__wrap">
				<span className="styled-checkbox inline-block border-2 border-blue w-5 h-5 relative mr-2">
					<Icon className="w-3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 checkmark" type={<Checkmark color="#43d18a" />} />
				</span>
				<span className=" flex-1 text-sm leading-5 font-medium text-blue text-left min1514px:whitespace-nowrap">{text}</span>
			</div>
		</label>
	);
};

export default Checkbox;