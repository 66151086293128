import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";
import Icon, { Checkmark } from "../Icons";

const ImageGroupWithText = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const listGroup = props.data && props.data.list_group && props.data.list_group.length > 0 && props.data.list_group.map( (listItem: any, index: any) => 
    (
      <li key={index} className="text-sm md:text-lg leading-relaxed mb-4 md:mb-6 flex text-left">
        <Icon className="w-5.5 relative top-1 md:top-2 mr-4.5" type={<Checkmark />} />
        { listItem }
      </li>
    )
  );

  return (
    <section ref={sectionEl} className="content__img-group-with-text">
      <div className="mx-auto max-w-[77.375rem] pt-14 pb-4 min1200px:flex md:pt-32 md:pb-32 text-blue">
        <div className="relative mx-auto w-full h-0 pb-[94.21%] min1200px:mr-20 md:pb-[55.25%] md:w-[58.64%] min1200px:mx-0">
          
          <div className="animate slide-from-top ease-in-out absolute top-0 left-[14.525%] w-[35.26%] h-0 pb-[35.26%] z-2" style={{
            background: `url('${props.data.image_group.top[0]}') center no-repeat`,
            backgroundSize: 'cover'
          }}>
            <img className="hidden" src={props.data.image_group.top[0]} alt="" />
          </div>

          <div className="animate slide-from-left ease-in-out absolute left-0 bottom-[14.32%] w-[41.32%] h-0 pb-[41.32%]" style={{
            background: `url('${props.data.image_group.left[0]}') center no-repeat`,
            backgroundSize: 'cover'
          }}>
            <img className="hidden" src={props.data.image_group.left[0]} alt="" />
          </div>

          <div className="animate slide-from-bottom ease-in-out w-[55.1%] h-0 pb-[82.64%] absolute right-0 bottom-0" style={{
            background: `url('${props.data.image_group.right[0]}') center no-repeat`,
            backgroundSize: 'cover'
          }}>
            <img className="hidden" src={props.data.image_group.right[0]} alt="" />
          </div>

        </div>
        <div className="animate slide-from-right ease-in-out text-blue text-center max-w-3xl mx-auto px-11 min1200px:px-0 md:text-left min1200px:mx-0 min1200px:max-w-md pt-17 min1200px:flex-1">
          <strong className="block text-xl md:text-4xl md:leading-snug">{ props.data.title }</strong>
          <p className="text-xs leading-5 mb-9 md:text-sm font-medium md:leading-6 mt-8 md:mb-11">{ props.data.description }</p>
          <ul className="mb-9 text-sm font-bold md:text-base">
            { listGroup }
          </ul>
        </div>
      </div>
    </section>
  );
}

export default ImageGroupWithText;