import { useEffect, useRef, Fragment } from "react";
import { onLoadScrollHandler } from "../../util/helpers";

const Steps = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
    }
  }

  useEffect(() => {
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const steps = props.data && props.data.steps && props.data.steps.length > 0 && props.data.steps.map( (step: any, index: any) => 
    (
      <Fragment key={index}>
        <div className="text-center relative steps__step">
          <span className="bg-altblue text-white rounded-full w-[74px] h-[74px] flex items-center justify-center font-semibold text-3.5xl mx-auto">{ index + 1 }</span>
          <span className="block pt-5 pb-10 md:hidden" dangerouslySetInnerHTML={{ __html: step }} />
        </div>
        <div className="dashed-line"></div>
      </Fragment>
    )
  );

  const stepsContent = props.data && props.data.steps && props.data.steps.length > 0 && props.data.steps.map( (step: any, index: any) => 
    (
      <div key={index} dangerouslySetInnerHTML={{ __html: step }} />
    )
  );

  return (
    <section ref={sectionEl} className="bg-white content__steps">
      <div className="animate fade-in-grow font-sans mx-auto max-w-5xl text-blue py-9 px-12 lg:px-0 md:py-32">
        <strong className="block font-semibold text-2xl md:text-3.5xl leading-normal text-center pb-14">{ props.data.title }</strong>
        <div className="md:flex w-full justify-between md:px-15 pb-4.5 steps__list">
          { steps }
        </div>
        <div className="hidden md:flex w-full justify-between text-center steps__content">
          { stepsContent }
        </div>
      </div>
    </section>
  );
}

export default Steps;