import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";
// import ButtonLink from "../Button/ButtonLink";

const TwoColCta = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const ctas = props.data && props.data.right_column.ctas && props.data.right_column.ctas.length > 0 && props.data.right_column.ctas.map( (cta: any, index: any) => 
    (
      <div key={index} className="flex last:mr-0 md:mr-5 md:block mb-4 last:mb-0 md:mb-0 justify-center">
        <span className="block font-bold text-xs md:text-sm mb-4 mr-4.5 md:mr-0" dangerouslySetInnerHTML={{ __html: cta.title }} />
        { 
        index % 2 ? 
          <a 
            href={cta.button_url} 
            target="_blank" 
            rel="noreferrer" 
            className="inline-block bg-skyblue rounded-3xl w-60 h-12 px-4 pt-3 pb-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base text-center hover:bg-skyblue-hover blue active:bg-skyblue-active max-w-[8.75rem]"
          >
            { cta.button_text }
          </a>
       : 
          <a 
            href={cta.button_url} 
            target="_blank" 
            rel="noreferrer" 
            className="inline-block bg-altblue rounded-3xl w-60 h-12 px-4 pt-3 pb-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base text-center hover:bg-altblue-hover blue active:bg-altblue-active max-w-[8.75rem]"
          >
            { cta.button_text }
          </a>
        }
      </div>
    )
  );

  return (
    <section ref={sectionEl} className="bg-beige py-10 md:py-32 text-blue content__two-col-cta">
      <div className="mx-auto w-full max-w-4xl px-12 lg:px-2 md:grid md:grid-cols-2">
        <div className="animate slide-from-left ease-in-out md:mr-20">
          <strong className="text-sm md:text-lg font-bold mb-8 md:mb-0 block">{ props.data.left_column }</strong>
        </div>
        <div className="animate slide-from-right ease-in-out">
          <div dangerouslySetInnerHTML={{ __html: props.data.right_column.text }} className="content__right-columm" />
          <div className="mt-9 md:flex md:mt-17 md:items-end">
            { ctas }
          </div>
        </div>
      </div>
    </section>
  );
}

export default TwoColCta;