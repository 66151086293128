import React from "react";
import Icon, { RightArrow, Send } from "../Icons";

interface IProps {
	className?: string;
	type?: 'primary' | 'secondary' | 'alternate' | 'contact' | 'disclaimer' | 'subscribe' | 'submit';
	// value?: string;
	// disabled?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<IProps> = ({
	className = '',
	type = 'primary',
	// value,
	// disabled,
	onClick = (e: React.MouseEvent<HTMLElement>) => {},
	children,
}) => {
	return (
		type === 'primary' ?
		<button 
			onClick={onClick} 
			className={
				'inline-block bg-altblue rounded-3xl w-60 px-4 py-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base hover:bg-altblue-hover blue active:bg-altblue-active ' + className
			} 
		>
			<span className="flex justify-center">
				<span className="inline-block pt-0.5">{children}</span>
				<Icon className="ml-4" type={<RightArrow />} />
			</span>
		</button>

		: type === 'secondary' ? 
		<button 
			onClick={onClick} 
			className={
				'inline-block bg-altblue rounded-3xl w-60 h-12 px-4 py-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base text-center hover:bg-altblue-hover blue active:bg-altblue-active ' + className
			} 
		>
			<span className="inline-block pt-0.5">{children}</span>
		</button>

		: type === 'alternate' ? 
		<button 
			onClick={onClick} 
			className={
				'inline-block bg-skyblue rounded-3xl w-60 h-12 px-4 py-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base text-center hover:bg-skyblue-hover blue active:bg-skyblue-active ' + className
			} 
		>
			<span className="inline-block pt-0.5">{children}</span>
		</button>

		: type === 'subscribe' ? 
		<button 
			className={
				'inline-block bg-green rounded-3xl w-36 h-12 px-4 py-2.5 mr-2 last:mr-0 text-blue font-bold font-sans text-base text-center hover:bg-green-hover blue active:bg-green-active active:text-white ' + className
			} 
		>
			<span className="inline-block pt-0.5">{children}</span>
		</button>

		: type === 'contact' ?
		<button 
			onClick={onClick} 
			className={
				'inline-block bg-white border border-blue rounded-3xl px-5 py-2.5 mr-2 last:mr-0 text-blue font-bold font-sans text-sm hover:bg-lightblue hover:border-lightblue active:bg-blue active:text-white active:border-blue ' + className
			} 
		>
			{children}
		</button>

		: type === 'disclaimer' ?
		<button 
			onClick={onClick} 
			className={
				'inline-block border border-blue rounded-3xl px-5 py-2.5 mr-2 last:mr-0 text-white bg-blue font-bold font-sans text-sm hover:bg-bluepurple hover:border-bluepurple active:bg-white active:text-blue active:border-blue ' + className
			} 
		>
			{children}
		</button>

		: type === 'submit' ? 
		<button 
			className={
				'inline-block bg-altblue rounded-3xl w-36 px-4 py-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base hover:bg-altblue-hover blue active:bg-altblue-active ' + className
			} 
		>
			<span className="flex justify-center">
				<span className="inline-block">{children}</span>
				<Icon className="ml-4" type={<Send />} />
			</span>
		</button>

		: 
    <button 
			onClick={onClick} 
			className={
				'inline-block bg-altblue rounded-3xl w-60 px-4 py-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base hover:bg-altblue-hover blue active:bg-altblue-active ' + className
			} 
		>
			<span className="flex justify-center">
				<span className="inline-block pt-0.5">{children}</span>
				<Icon className="ml-4" type={<RightArrow />} />
			</span>
		</button>
	);
};

export default Button;