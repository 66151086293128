import { useEffect, useRef } from "react";
import ButtonLink from "../Button/ButtonLink";
import Icon, { Checkmark } from "../Icons";
// import { onLoadScrollHandler } from "../../util/helpers";
import bgLines from '../../img/bg-lines_2x.png';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const HeroSection = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  /* const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  } */

  useEffect(() => {
    /* gsap.fromTo(
      sectionEl.current,
      {
        // opacity: 0,
        // y: 250
      },
      {
        // opacity: 1,
        // y: 0,
        scrollTrigger: {
          trigger: sectionEl.current,
          start: "top center",
          // start: "center center",
          end: "bottom top",
          // scrub: true
          toggleClass: "on-screen",
          // toggleActions: "play reverse none none",
          // markers: true,
          once: true,
        }
      }
    ); */

    ScrollTrigger.create({
      trigger: sectionEl.current,
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });

    // scrollHandler();
    /* onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler); */
  }, []);

  const listGroup = props.data.list_group && props.data.list_group.length > 0 && props.data.list_group.map( (listItem: any, index: any) => 
    (
      <li key={index} className="mt-5 first:mt-0 flex">
        <Icon className="w-5.5 mr-4.5" type={<Checkmark />} />
        <span className="flex-1">{ listItem }</span>
      </li>
    )
  );

  const wrapEachWord = (str: string) => {
    return str.replace(/(^|<\/?[^>]+>|\s+)([^\s<]+)/g, '$1<span class="word"><em>$2</em></span>');
  }
  
  return (
    <section ref={sectionEl} className="content__hero-section w-full pb-15 md:pb-24 bg-no-repeat bg-center-bottom-86 sm:bg-center-bottom" style={{
      backgroundImage: `url('${bgLines}')`,
      backgroundSize: '100% auto'
    }}>
      {/* center bottom no-repeat */}
      <div className="max-w-9xl mx-auto pt-10.5 items-center md:pt-17 md:px-17 lg:flex min1200px:pt-40 min1200px:px-26 min1200px:items-start">
        <div className="mx-auto px-12 md:mx-0 md:px-0 lg:mr-15.5">
          <h1 className="animate slide-from-left ease-in-out delay-300 text-blue font-sans font-semibold text-5xl leading-supertight relative mx-auto text-center lg:mx-0 lg:text-left min1200px:text-7xl min1200px:leading-none max-w-65.5 min1200px:max-w-[34rem]">
            <span className="relative z-10 h1__underlined" dangerouslySetInnerHTML={{ __html: wrapEachWord(props.data.heading) }} />
          </h1>
          <div className="">
            <p className="animate slide-from-left ease-in-out delay-500 text-center text-blue font-sans max-w-md leading-5 pt-4 text-xs mx-auto min1200px:max-w-140 lg:pt-10 lg:leading-10 lg:mx-0 lg:text-3.5xl lg:text-left">{ props.data.description }</p>
            <ul className="animate slide-from-left ease-in-out delay-700 font-sans text-sm font-bold text-blue leading-18px pt-8 pb-8 mx-auto max-w-md lg:max-w-none lg:mx-0 min1200px:leading-5 min1200px:pt-10 min1200px:pb-17 min1200px:text-lg">
              { listGroup }
            </ul>
            <div className="animate slide-from-left ease-in-out delay-1000 text-center lg:text-left">
              <ButtonLink type="primary" link={'/'+props.data.cta.url.post_name}>
                { props.data.cta.text }
              </ButtonLink>
            </div>
          </div>
        </div>
        <div className="relative mx-auto mt-11 w-full pb-[70.9333%] lg:w-[52.186%] lg:pb-[32%] lg:mx-0 lg:mt-0 min1200px:pb-[49.854%] min1200px:-mt-12.5">
          
          <div className="animate slide-from-top ease-in-out z-2 absolute top-0 left-0 w-[52.8%] h-0 pb-[52.8%] overflow-hidden shadow-img min1200px:left-[14.525%] min1200px:w-[35.7%] min1200px:pb-[35.7%]" style={{
            background: `url('${props.data.image_group.top[0]}') center no-repeat`,
            backgroundSize: 'cover'
          }}>
            <img className="opacity-0" src={props.data.image_group.top[0]} alt="" />
          </div>

          <div className="animate slide-from-left ease-in-out absolute right-0 bottom-0 w-[61.87%] h-0 pb-[61.87%] overflow-hidden shadow-img min1200px:bottom-auto min1200px:right-auto min1200px:top-[41.2%] min1200px:w-[41.9%] min1200px:pb-[41.9%]" style={{
            background: `url('${props.data.image_group.left[0]}') center no-repeat`,
            backgroundSize: 'cover'
          }}>
            <img className="opacity-0" src={props.data.image_group.left[0]} alt="" />
          </div>

          <div className="animate slide-from-right ease-in-out w-[54.6%] h-0 pb-[83.8%] absolute right-0 bottom-0 overflow-hidden shadow-img hidden min1200px:block" style={{
            background: `url('${props.data.image_group.right[0]}') center no-repeat`,
            backgroundSize: 'cover'
          }}>
            <img className="opacity-0" src={props.data.image_group.right[0]} alt="" />
          </div>

        </div>
      </div>
    </section>
  );
}

export default HeroSection;