import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";

const LeftImageWithText = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section ref={sectionEl} className="content__left-img-with-txt pb-8 md:pb-32">
      <div className="text-blue mx-auto max-w-5xl px-12 md:px-20 md:flex md:items-center">
        <div className="animate slide-from-left ease-in-out mb-8 md:basis-2/4 md:mr-11">
          <img className="w-full max-w-full" src={ props.data.image[0] } alt="" />
        </div>
        <div className="animate slide-from-right ease-in-out md:basis-2/4 font-medium text-xs md:text-sm" dangerouslySetInnerHTML={{ __html: props.data.text }} />
      </div>
    </section>
  );
}

export default LeftImageWithText;