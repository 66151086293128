// import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import ButtonLink from "./Button/ButtonLink";

const Navigation = (props: any) => {
  const menuItems = props.data.menu && props.data.menu.length > 0 && props.data.menu.map( (menuItem: any, index: any) => 
    (
      <li 
        key={index} 
        className="inline-block lg:ml-4 min1514px:ml-8 first:ml-0"
      >
        <NavLink
          to={'/'+menuItem.slug} 
          className={'font-sans text-sm min1514px:text-base font-semibold hover:underline navlink' + (props.altHeader ? ' text-white' : '')}
        >
          {menuItem.title}
        </NavLink>
      </li>
    )
  );

  const ctaBtns = props.data.ctas && props.data.ctas.length > 0 && props.data.ctas.map( (btn: any, index: any) => 
    (
      index === 0 ? 

      <ButtonLink 
        key={index} 
        type="contact" 
        link={'/'+btn.button_link.post_name} 
        className={props.altHeader ? 'contact--alt transition-all duration-300' : ''} 
      >
        {btn.button_text}
      </ButtonLink> 

      : 

      <ButtonLink 
        key={index} 
        type="disclaimer" 
        link={'/'+btn.button_link.post_name} 
        className={props.altHeader ? 'disclaimer--alt' : ''} 
      >
        {btn.button_text}
      </ButtonLink>
    )
  );

  return (
    <nav className={'flex mx-auto w-full max-w-[98.625rem] items-center justify-between pt-14 pb-8.5 min1186px:-mb-[1px] relative' + (props.altHeader ? ' nav--alt' : ' nav--default')}>
      <div>
        <Link
          to="/" 
          className="w-64 min1578px:w-96 block mr-4 min1216px:mr-8 min1578px:mr-12 hover:opacity-80"
        >
          {
          props.altHeader ?
            <img alt="logo" src={props.data.logo_alt[0]} className="block max-w-full" />
          :
            <img alt="logo" src={props.data.logo[0]} className="block max-w-full" />
          }
        </Link>
      </div>
      <ul className="block xl:whitespace-nowrap">
        { menuItems }
      </ul>
      <div className="ml-4 min1216:ml-8 min1578px:ml-12 text-right self-baseline xl:whitespace-nowrap">
        { ctaBtns }
      </div>
    </nav>
  )
}

export default Navigation;