import ButtonLink from "../components/Button/ButtonLink";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ThankYou = () => {
  return (
    <>
    <Header />
    <div className="bg-white flex items-center flex-wrap mt-[1px]">
      <div className="container mx-auto px-5 pt-30 pb-12 md:py-40 text-center max-w-md">
        <h1 className="text-blue font-sans font-semibold text-5xl leading-supertight relative mx-auto text-center lg:mx-0 lg:text-left min1200px:text-7xl min1200px:leading-none max-w-65.5 min1200px:max-w-[34rem]">Thank You.</h1>
        <p className="text-xs leading-loose md:text-sm md:leading-breezy font-medium mt-1 mb-11">We appreciate you connecting with us. A representative will be in touch within the next 48 hours.</p>
        <div className="max-w-sm px-6">
          <ButtonLink
            link="/investing" 
            type="disclaimer"
            className="w-full h-12 md:text-base md:leading-none inline-flex items-center justify-center"
          >
            Invest with Abbey Ridge MIC
          </ButtonLink>
          <ButtonLink
            link="/" 
            type="contact"
            className="w-full mt-3 text-altblue border-altblue h-12 md:text-base md:leading-none inline-flex items-center justify-center"
          >
            Back to Home
          </ButtonLink>
        </div>
      </div>
      <div className="bg-white pb-2.5 w-full content__disclaimer">
        <div className="w-full max-w-screen-min1170px mx-auto pl-8 pr-8 text-center text-xxs leading-[1.4] md:pl-12 md:pr-12 lg:pl-3 lg:pr-3">
          <p>This balance of the market (approximately 20%) is referred to as the “non bank” lending segment. Operating in this space requires a more proprietary lending model, with the opportunity for increased returns. This is also where Abbey Ridge, through its decades of expertise, is able to select what are regarded as “non-bank” loans without taking on any additional credit risk, and deliver higher returns to Investors.</p>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default ThankYou;
