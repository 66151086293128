import React from "react";

interface IProps {
  color?: string;
}

const IconMapPin: React.FC<IProps> = ({
  color = '#83f1aa'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24.452" height="32" viewBox="0 0 24.452 32">
      <g id="Group_28" data-name="Group 28">
        <path id="Path_55" data-name="Path 55" d="M12.226,0A12.357,12.357,0,0,0,0,12.457c0,5.478,7.452,14.974,10.654,18.8a2.037,2.037,0,0,0,3.143,0c3.2-3.828,10.655-13.324,10.655-18.8A12.357,12.357,0,0,0,12.226,0m.182,30.1c-.125.15-.237.15-.365,0-2.4-2.868-10.232-12.619-10.232-17.64A10.545,10.545,0,0,1,12.226,1.81,10.545,10.545,0,0,1,22.641,12.457c0,5.021-7.832,14.772-10.233,17.64" fill={color}/>
        <path id="Path_56" data-name="Path 56" d="M12.226,5.108a7.179,7.179,0,0,0,0,14.355,7.179,7.179,0,0,0,0-14.355m0,12.543a5.367,5.367,0,0,1,0-10.732,5.367,5.367,0,0,1,0,10.732" fill={color}/>
      </g>
    </svg>
  );
};

export default IconMapPin;
