import { useState, useRef, useEffect } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../Button/Button";

const apiUrl = process.env.REACT_APP_API_URL;

// interface IProps {
// 	popup?: boolean;
// }

const initialFormValues = {
  firstname: '',
  lastname: '',
  email: '',
}

const MailChimpForm = () => {
// const MailChimpForm: React.FC<IProps> = ({
// 	popup = false
// }) => {
  // const [userEmail, setUserEmail] = useState('');
  // const [userFirstname, setUserFirstname] = useState('');
  // const [userLastname, setUserLastname] = useState('');
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaCompleted, setCaptchaCompleted] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  
  const formEl = useRef<HTMLFormElement>(null);
  const recaptchaRef = useRef(null) as any;
  
  const [formValues, setFormValues] = useState(initialFormValues);

  useEffect(() => {
    recaptchaRef.current?.reset();
  }, []);

  const validateForm = () => {
    // return new Promise(function(resolve, reject) {
      if( !formValues.firstname || !formValues.lastname || !formValues.email ) {
        setErrors(true);
        return false;
      }
      else {
        setErrors(false);
        return true;
        // submitForm();
      }

      // resolve(true);
    // });
  }

  const handleInputValues = () => {
    setFormValues({
      firstname: formEl.current?.firstname.value,
      lastname: formEl.current?.lastname.value,
      email: formEl.current?.email.value,
    });

    validateForm();
  }

  /* const handleChange = (value: any) => {
    setUserEmail(value);
    setUserFirstname(value);
    setUserLastname(value);
  } */

  const handleRecaptcha = (value: any) => {
    // console.log("Captcha value:", value);
    setCaptchaCompleted(true);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // setErrors(false);

    let isFormValid = validateForm();
    if( isFormValid && captchaCompleted ) {
      submitForm();
    }
    else {
      setCaptchaError(true);
    }

    /* if( !captchaCompleted ) {
      setCaptchaError(true);
    }
    else {
      axios.post(`${apiUrl}/mailchimp`, {
        email: userEmail,
        firstanme: userEmail,
        lastname: userEmail,
      })
      .then(function (response) {
        // console.log(response.data);
        // if( Math.round(parseFloat(response.data.status) / 100) !== 2 ) {
        if( response.data.status !== 'subscribed' ) {
          setErrors(true);
          setErrorMessage(response.data.title);
          setTimeout(() => {
            setErrors(false);
            setErrorMessage('');
          }, 3000);
        }
        else {
          setUserEmail('');
          setUserFirstname('');
          setUserLastname('');
          setErrors(false);
          setErrorMessage('Thanks for subscribing!');
          setTimeout(() => {
            setErrorMessage('');
          }, 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    } */
  }

  const submitForm  = () => {
    axios.post(`${apiUrl}/mailchimp`, formValues)
    .then(function (response) {
      // console.log(response.data);
      // if( Math.round(parseFloat(response.data.status) / 100) !== 2 ) {
      if( response.data.status !== 'subscribed' ) {
        setErrors(true);
        setErrorMessage(response.data.title);
        setTimeout(() => {
          setErrors(false);
          setErrorMessage('');
        }, 3000);
      }
      else {
        // setUserEmail('');
        // setUserFirstname('');
        // setUserLastname('');
        setErrors(false);
        setErrorMessage('Thanks for subscribing!');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <div className="mailchimp-form">
      <form ref={formEl} onSubmit={handleSubmit}>
        <div className="md:flex gap-x-3">
          <input 
            type="text" 
            placeholder="First Name" 
            required 
            // value={userFirstname}
            name="firstname"
            onChange={handleInputValues}
            className={'w-full h-10 mb-4 text-1.5xl px-5 rounded-30px text-black mailchimp-fname-field'+(errors && !formValues.firstname ? ' error' : '')}
          />
          <input 
            type="text" 
            placeholder="Last Name" 
            required 
            // value={userLastname}
            name="lastname"
            onChange={handleInputValues}
            className={'w-full h-10 mb-4 text-1.5xl px-5 rounded-30px text-black mailchimp-lname-field'+(errors && !formValues.lastname ? ' error' : '')}
          />
        </div>
        <div>
          <input 
            type="email" 
            placeholder="Enter your email" 
            required 
            // value={userEmail}
            name="email"
            onChange={handleInputValues}
            className={'w-full h-10 mb-4 text-1.5xl px-5 rounded-30px text-black mailchimp-email-field'+(errors && !formValues.email ? ' error' : '')}
          />
        </div>
        <div className="recaptcha-wrap pt-4 pb-8 mx-auto max-w-[304px] lg:max-w-full lg:m-0">
          <ReCAPTCHA
            onChange={handleRecaptcha}
            ref={recaptchaRef}
            // @ts-ignore
            sitekey={process.env.REACT_APP_SITE_KEY}
          />
          {
            !captchaCompleted && captchaError && 
            <p className="text-red text-xs font-medium leading-tight mt-1.5 text-left">Please verify that you're not a robot</p>
          }
        </div>
        <Button type="subscribe">Subscribe</Button>
      </form>
      {
       errorMessage && 
        <div className="mt-2">{ errorMessage }</div>
      }
    </div>
  );
}

export default MailChimpForm;