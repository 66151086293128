import { useEffect, useRef } from "react";
import ButtonLink from "../Button/ButtonLink";
import { onLoadScrollHandler } from "../../util/helpers";

const TwoColMixed = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section ref={sectionEl} className="md:pt-30 content__two-col-mixed">
      <div className="font-sans md:max-w-6xl px-12 pb-14 md:pb-30 mx-auto md:grid md:grid-cols-2">
        <div className="animate slide-from-left ease-in-out md:mr-20">
          <h2 className="h2">{ props.data.title }</h2>
          <div className="content" dangerouslySetInnerHTML={{ __html: props.data.column_1 }} />
        </div>
        <div className="animate slide-from-right ease-in-out">
          <div className="w-full pb-[100%]" style={{
            background: `url('${props.data.column_2.image[0]}') center no-repeat`,
            backgroundSize: 'cover'
          }}>
            <img className="hidden" src={ props.data.column_2.image[0] } alt="" />
          </div>
          <div className="mt-4 mb-8 w-full md:my-9 md:max-w-120 column-2" dangerouslySetInnerHTML={{ __html: props.data.column_2.description }} />
          <div>
            <ButtonLink type="secondary" link={'/'+props.data.column_2.cta.url.post_name}>
              { props.data.column_2.cta.text }
            </ButtonLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TwoColMixed;