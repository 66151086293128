import { useEffect } from 'react';

import AppRoutes from "./routes/Routes";

const App = () => {
  useEffect(() => {
    // Initialize the hs-script-loader
    if (typeof window !== 'undefined' && window.hbspt) {
      window.hbspt.init({ portalId: '43645547' });
    }
  }, []);

  return (
    <div className="App bg-beige min-h-screen max-w-[100vw] overflow-x-hidden">
      <AppRoutes />
    </div>
  );
}

export default App;
