import React from "react";

interface IProps {
  color?: string;
}

const IconDownload: React.FC<IProps> = ({
  color = '#0f204c'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="31.49" height="32" viewBox="0 0 31.49 32">
      <g id="Download_Button" data-name="Download Button" transform="translate(0 0)">
        <g id="Group_71" data-name="Group 71" transform="translate(0 0)">
          <path id="Path_240" data-name="Path 240" d="M18.026,23.282a1.611,1.611,0,0,0,2.274,0l9.025-9.025a1.606,1.606,0,0,0,0-2.273,1.645,1.645,0,0,0-2.274,0l-6.281,6.28V1.608a1.608,1.608,0,1,0-3.216,0V18.263l-6.28-6.28a1.647,1.647,0,0,0-2.275,0,1.606,1.606,0,0,0,0,2.273Z" transform="translate(-3.418 0)" fill={color}/>
          <path id="Path_241" data-name="Path 241" d="M29.991,32.295a1.5,1.5,0,0,0-1.5,1.5v8.154H3V33.793a1.5,1.5,0,0,0-3,0v9.652a1.5,1.5,0,0,0,1.5,1.5H29.991a1.5,1.5,0,0,0,1.5-1.5V33.793a1.5,1.5,0,0,0-1.5-1.5" transform="translate(0 -12.943)" fill={color}/>
        </g>
      </g>
    </svg>
  );
};

export default IconDownload;
