import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const GlobalContext = React.createContext({} as any);

export const GlobalContextProvider = (props: any) => {
  const [globalContent, setGlobalContent] = useState() as any;

  useEffect(() => {
    axios.get(`${apiUrl}/global/get`)
    .then( response =>  {
      let global = {
        apiUrl: apiUrl,
        global: response.data
      }
      setGlobalContent(global);
    })
    .catch( error => {
      console.error(error);
    });
  }, []);

  return (
    <>
    {
      globalContent && 
      <GlobalContext.Provider value={globalContent}>
        {props.children}
      </GlobalContext.Provider>
    }
    </>
  );
};

export default GlobalContext;