import React from "react";

interface IProps {
	className?: string;
  type: React.ReactNode;
}

export const Icon: React.FC<IProps> = ({
  className = '',
  type = ''
}) => {
	return (
    <span className={'inline-block svg-icon ' + className}>
      {type}
    </span>
  );
};

export default Icon;
export {default as Checkmark} from './Main/Checkmark';
export {default as RightArrow} from './Main/RightArrow';
export {default as Phone} from './Main/Phone';
export {default as Mail} from './Main/Mail';
export {default as Facebook} from './Social/Facebook';
export {default as Instagram} from './Social/Instagram';
export {default as LinkedIn} from './Social/LinkedIn';
export {default as Quotes} from './Main/Quotes';
export {default as RightArrowNoBorder} from './Main/RightArrowNoBorder';
export {default as Download} from './Main/Download';
export {default as Send} from './Main/Send';
export {default as MapPin} from './Main/MapPin';
export {default as HamburgerMenu} from './Main/HamburgerMenu';
export {default as CheckmarkCircle} from './Main/CheckmarkCircle';
export {default as CloseX} from './Main/CloseX';
