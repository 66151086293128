import { useEffect, useRef } from "react";
import ButtonLink from "../Button/ButtonLink";
import { onLoadScrollHandler } from "../../util/helpers";

const LeftImageWithCta = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section ref={sectionEl} className="content__left-img-with-cta py-8 md:py-30">
      <div className="w-full max-w-7xl mx-auto px-12 md:px-32 text-blue font-sans md:grid md:grid-cols-2">
        <div className="animate slide-from-left ease-in-out w-full pb-[100%] flex-auto overflow-hidden hidden md:block" style={{
          background: `url('${props.data.image[0]}') center no-repeat`,
          backgroundSize: 'cover'
        }}>
          <img className="hidden" src={props.data.image[0]} alt="" />
        </div>
        <div className="animate slide-from-right ease-in-out md:pl-20 basis-2/4 flex items-center">
          <div>
          <h2 className="h2">{ props.data.title }</h2>
          <p className="font-medium text-xs leading-5 pt-4 md:max-w-xs md:text-sm md:leading-6">{ props.data.description }</p>
          <div className="mt-8 md:mt-11">
            <ButtonLink type="secondary" link={'/'+props.data.cta.url.post_name}>
              { props.data.cta.text }
            </ButtonLink>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeftImageWithCta;