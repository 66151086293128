import { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
// import { onLoadScrollHandler } from "../../util/helpers";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Stats = (props: any) => {
  const sectionEl = useRef<HTMLDivElement>(null);
  const [onScreen, setOnScreen] = useState(false);
  gsap.registerPlugin(ScrollTrigger);

  /* const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        // sectionEl.current?.classList.add('on-screen');
        setOnScreen(true);
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  } */

  useEffect(() => {
    /* gsap.fromTo(
      sectionEl.current,
      {
        // opacity: 0,
        // y: 250
      },
      {
        // opacity: 1,
        // y: 0,
        scrollTrigger: {
          trigger: sectionEl.current,
          start: "top center",
          // start: "center center",
          end: "bottom top",
          // scrub: true
          toggleClass: "on-screen",
          // toggleActions: "play reverse none none",
          // markers: true,
          once: true,
        }
      }
    ); */

    ScrollTrigger.create({
      trigger: sectionEl.current,
      start: "center+=50px bottom",
      end: "bottom top",
      // markers: true,
      once: true,
      onEnter: self => setOnScreen(true),
    });

    /* // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler); */
  }, []);

  const stats = props.data.stats && props.data.stats.length > 0 && props.data.stats.map( (stat: any, index: any) => 
    (
      <div key={index} className="text-blue font-sans mr-12 even:mr-0 mb-8 lg:mb-0 md:even:mr-8 md:mr-8 md:flex lg:even:mr-14 lg:mr-14">
        <img className="w-12 h-12 mr-3 mb-1.5 md:mb-0" src={stat.icon.url} alt={stat.title} />
        <div className="">
          <div className="text-4xl font-medium leading-0 md:text-3xl lg:text-5.5xl md:mb-6">
            <span>{ stat.stat_prefix }</span>
            { onScreen ? 
              <CountUp 
                end={stat.stat} 
                decimals={stat.stat_decimals}
              />
            : 
              <span>{ stat.stat }</span>
            }
            <span>{ stat.stat_suffix }</span>
          </div>
          <div className="block">
            <span className="block text-xs md:text-sm font-semibold">{ stat.title }</span>
            <span className="block text-xxs md:text-11px font-semibold leading-5">{ stat.subtitle }</span>
          </div>
        </div>
      </div>
    )
  );

  return (
    <div ref={sectionEl} className="content__stats bg-white py-12 lg:rounded-3xl drop-shadow-stats md:py-14">
      <div className="w-full max-w-9xl mx-auto px-12 md:px-12 lg:px-44">
        <h2 className="font-sans font-semibold tracking-widest text-blue text-base leading-5 uppercase text-center mb-2.5">{ props.data.title }</h2>
        <p className="font-sans font-bold text-blue text-sm leading-6 max-w-6xl mx-auto text-center mb-8 md:px-7 md:text-lg">{ props.data.description }</p>
        <div className="flex justify-center">
          <div className="grid grid-cols-2 lg:flex">
            { stats }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;