// import React from "react";

export const triggerScroll = () => {
  // console.log('scroll triggered');
  // window.scrollTo(0, 1);
  window.scrollTo(0, document.body.scrollHeight);
  console.log('document.body.scrollHeight', document.body.scrollHeight);
  window.scrollTo(0, 0);
  console.log('scrollTo', 0);
}

export const onLoadScrollHandler = (sectionEl: any) => {
  let bounding = sectionEl.current?.getBoundingClientRect();

  if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
    sectionEl.current?.classList.add('on-screen');
  }
}