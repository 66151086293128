import React from "react";

interface IProps {
	className?: string;
	placeholder?: string;
	name?: string;
	// value?: string;
	type: string;
	// disabled?: boolean;
	// required?: boolean;
	isRequired?: boolean;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	onBlur: React.FocusEventHandler<HTMLInputElement>;
}

const Input: React.FC<IProps> = ({
	className = '',
	type = 'text',
	// value,
	// disabled,
	// children,
	placeholder,
	name,
	// required,
	isRequired,
	onChange,
	onBlur,
}) => {
	return (
		<div className="relative">
		{
			isRequired ? 
			<span className="font-medium text-base leading-5 text-red inline-block absolute top-4 left-0">*</span>
			: null
		}
		<input
      type={type}
      placeholder={placeholder}
      name={name}
      // required={required} 
      className={'block border-b border-blue font-medium text-base leading-5 py-4 w-full ' + (isRequired ? 'pl-2 ' : '') + className} 
			onChange={(e) => onChange(e)} 
			onBlur={(e) => onBlur(e)} 
    />
		</div>
	);
};

export default Input;