import { useEffect, useRef } from "react";
import MailChimpForm from "./MailChimp/MailChimpForm";
import { onLoadScrollHandler } from "../../util/helpers";

const NewsletterTile = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section ref={sectionEl} className="content__newsletter-tile bg-blue px-12">
      <div className="font-sans text-white">
        { props.data.show_image ?
          <div className="mx-auto max-w-1090px py-8 md:py-20 lg:flex gap-x-15">
            <div className="animate slide-from-left ease-in-out hidden mx-auto lg:m-0 md:block max-w-[32.5rem] flex-shrink-0">
              <img className="w-full" src={props.data.image[0]} alt="" />
            </div>
            <div className="animate slide-from-right ease-in-out text-center lg:text-left">
              <h2 className="font-display italic text-3xl md:text-64px leading-none my-4 block">{ props.data.heading }</h2>
              <strong className="font-semibold text-base md:font-bold md:text-2xl leading-8 mb-2 md:mb-4 block">{ props.data.title }</strong>
              <p className="font-medium text-xs md:text-sm leading-5 md:leading-6 mb-6 md:mb-9 max-w-2xl mx-auto lg:max-w-full">{ props.data.description }</p>
              <MailChimpForm />
            </div>
          </div>
          :
          <div className="mx-auto max-w-1090px py-8 lg:px-1.5 lg:py-20 lg:flex">
            <div className="animate slide-from-left ease-in-out mx-auto lg:m-0 lg:mr-12 max-w-[458px] text-center lg:text-left">
              <h2 className="font-display italic text-3xl lg:text-5xl leading-none mb-4 block">{ props.data.heading }</h2>
              <strong className="font-semibold text-lg lg:font-bold lg:text-2xl leading-8 mb-2 lg:mb-5 block">{ props.data.title }</strong>
              <p className="font-medium text-xs leading-5 lg:text-sm lg:leading-6 mb-6 lg:mb-9">{ props.data.description }</p>
            </div>
            <div className="animate slide-from-right ease-in-out max-w-[458px] mx-auto lg:m-0 text-center lg:text-left">
              <MailChimpForm />
            </div>
          </div>
        }
      </div>
    </section>
  );
}

export default NewsletterTile;