import { useEffect, useRef } from "react";
// import { onLoadScrollHandler } from "../../util/helpers";
import Icon, { Checkmark } from "../Icons";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ListWithImage = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  /* const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  } */

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });

    /* // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler); */
  }, []);

  const listGroup = props.data && props.data.list_group && props.data.list_group.length > 0 && props.data.list_group.map( (listItem: any, index: any) => 
    (
      <li key={index} className="text-sm md:text-lg md:leading-relaxed md:mb-10 mb-5 flex">
        <Icon className="w-4 mr-2.5 md:w-auto md:mr-4.5 relative top-1 md:top-2" type={<Checkmark />} />
        <span className="flex-1">{ listItem }</span>
      </li>
    )
  );

  return (
    <section ref={sectionEl} className="py-9 md:py-40 content__list-with-image">
      <div className="mx-auto w-full max-w-7xl px-9 md:grid md:grid-cols-2">
        <div className="animate slide-from-left ease-in-out md:mr-10">
          <ul className="text-blue mb-9 font-bold">
            { listGroup }
          </ul>
          <blockquote className="text-lg">
            <p>{ props.data.callout_text }</p>
          </blockquote>
        </div>
        <div className="animate slide-from-right ease-in-out mt-8 md:mt-0 md:max-w-140" style={{
          background: `url('${props.data.image[0]}') center no-repeat`,
          backgroundSize: 'cover'
        }}>
          <img src={props.data.image[0]} alt="" className="opacity-0" />
        </div>
      </div>
    </section>
  );
}

export default ListWithImage;