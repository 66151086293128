import React from "react";

interface IProps {
  color?: string;
}

const IconSend: React.FC<IProps> = ({
  color = '#83f1aa'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path id="Send_Icon" data-name="Send Icon" d="M0,0V6.9L10.557,9,0,11.1V18L18,9Z" transform="translate(0 0)" fill={color}/>
    </svg>
  );
};

export default IconSend;
