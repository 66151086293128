import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";
 
const Disclaimer = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
    }
  }
  
  useEffect(() => {
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);


  return (
    <section ref={sectionEl} className="bg-white py-10 content__disclaimer">
      <div
        className="animate fade-in-grow ease-in-out w-full max-w-screen-min1170px mx-auto pl-8 pr-8 text-center text-xxs leading-[1.4] md:pl-12 md:pr-12 lg:pl-3 lg:pr-3"
        dangerouslySetInnerHTML={{ __html: props.data.content }}
      />
    </section>
  );
}

export default Disclaimer;