import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
// import ButtonLink from "./Button/ButtonLink";
import Icon, { HamburgerMenu } from "./Icons";

const MobileNav = (props: any) => {
  const [menuActive, setMenuActive] = useState(false);

  const handleClick = () => {
    menuActive ? setMenuActive(false) : setMenuActive(true);
  }

  const menuItems = props.data.menu && props.data.menu.length > 0 && props.data.menu.map( (menuItem: any, index: any) => 
    (
      <li 
        key={index} 
        className="group"
      >
        <NavLink 
          to={'/'+menuItem.slug} 
          className="block font-sans text-base text-blue font-medium py-2.5 px-3 bg-white border-b border-[#e2e8f0] group-last:border-0 hover:border-[#e2e8f0] hover:bg-[#f8fafc] hover:text-blue navlink__mobile"
          onClick={(e) => setMenuActive(false)} 
        >
          {menuItem.title}
        </NavLink>
      </li>
    )
  );

  const ctaBtns = props.data.ctas && props.data.ctas.length > 0 && props.data.ctas.map( (btn: any, index: any) => 
    (
      <li 
        key={index} 
        className="group"
      >
        <NavLink 
          to={'/'+btn.button_link.post_name} 
          className="block font-sans text-base text-blue font-medium py-2.5 px-3 bg-white border-b border-[#e2e8f0] group-last:border-0 hover:border-[#e2e8f0] hover:bg-[#f8fafc] hover:text-blue navlink__mobile"
          onClick={(e) => setMenuActive(false)} 
        >
          {btn.button_text}
        </NavLink>
      </li>
    )
  );

  return (
    <nav>
      <div className="w-full flex items-center justify-between">
        <div>
          <Link
            to="/" 
            className="w-[15.5rem] block mr-4" 
            onClick={(e) => setMenuActive(false)} 
          >
            <img alt="logo" src={props.data.logo[0]} className="block max-w-full" />
          </Link>
        </div>
        <button onClick={handleClick}>
          <Icon type={<HamburgerMenu />} />
        </button>
      </div>
      <ul className={'mt-12 mb-4 '+(menuActive ? 'block' : 'hidden')}>
        { menuItems }
        { ctaBtns }
      </ul>
    </nav>
  )
}

export default MobileNav;