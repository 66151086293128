import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";

const ListGroup = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const listGroup = props.data && props.data.list_items && props.data.list_items.length > 0 && props.data.list_items.map( (listItem: any, index: any) => 
    (
      <li key={index} className="mb-6">{ index + 1 }. { listItem }</li>
    )
  );

  return (
    <section ref={sectionEl} className="bg-blue content__list-group">
      <div className="font-sans mx-auto max-w-4xl px-12 md:px-7 text-white py-9 md:py-30">
        <h2 className="font-bold text-lg md:text-2xl mb-2">{ props.data.heading }</h2>
        <strong className="block text-green text-bold text-sm md:text-lg mb-8 md:mb-10">{ props.data.heading }</strong>
        <ol className="animate slide-from-top ease-in-out hidden md:block list-none text-sm font-medium" style={{ 
          columns: (props.data.two_columns ? '2' : '1'),
          columnGap: '2.75rem'
        }}>
          { listGroup }
        </ol>
        <ol className="md:hidden list-none text-sm font-medium">
          { listGroup }
        </ol>
      </div>
    </section>
  );
}

export default ListGroup;