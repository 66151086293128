import React from "react";
import { Link } from "react-router-dom";
import Icon, { RightArrow } from "../Icons";

interface IProps {
	className?: string;
	type?: 'primary' | 'secondary' | 'alternate' | 'contact' | 'disclaimer' | 'subscribe' | 'submit' | 'large';
	// value?: string;
	// disabled?: boolean;
  link: string;
	// onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonLink: React.FC<IProps> = ({
	className = '',
	type = 'primary',
	// value,
	// disabled,
  link = '#',
	// onClick = (e: React.MouseEvent<HTMLElement>) => {},
	children,
}) => {
	return (
		type === 'primary' ?
		<Link 
			to={link} 
			className={
				'inline-block bg-altblue rounded-3xl w-60 px-4 py-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base hover:bg-altblue-hover blue active:bg-altblue-active ' + className
			} 
		>
			<span className="flex justify-center">
				<span className="inline-block pt-0.5 mr-4">{children}</span>
				<Icon className="w-7" type={<RightArrow />} />
			</span>
		</Link>

		: type === 'secondary' ? 
		<Link 
			to={link} 
			className={
				'inline-block bg-altblue rounded-3xl w-60 h-12 px-4 py-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base text-center hover:bg-altblue-hover blue active:bg-altblue-active ' + className
			} 
		>
			<span className="inline-block pt-0.5">{children}</span>
		</Link>

		: type === 'alternate' ? 
		<Link 
			to={link} 
			className={
				'inline-block bg-skyblue rounded-3xl w-60 h-12 px-4 py-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base text-center hover:bg-skyblue-hover blue active:bg-skyblue-active ' + className
			} 
		>
			<span className="inline-block pt-0.5">{children}</span>
		</Link>

		: type === 'subscribe' ? 
		<Link 
			to={link} 
			className={
				'inline-block bg-green rounded-3xl w-36 h-12 px-4 py-2.5 mr-2 last:mr-0 text-blue font-bold font-sans text-base text-center hover:bg-green-hover blue active:bg-green-active active:text-white ' + className
			} 
		>
			<span className="inline-block pt-0.5">{children}</span>
		</Link>

		: type === 'contact' ?
		<Link 
			to={link} 
			className={
				'inline-block bg-white border border-blue rounded-3xl px-5 py-2.5 mr-2 last:mr-0 text-blue font-bold font-sans text-sm hover:bg-lightblue hover:border-lightblue active:bg-blue active:text-white active:border-blue ' + className
			} 
		>
			{children}
		</Link>
		
		: type === 'large' ?
		<Link 
			to={link} 
			className={
				'block bg-altblue rounded-32px w-60 px-4 py-4.5 text-white font-bold font-sans text-base hover:bg-altblue-hover blue active:bg-altblue-active ' + className
			} 
		>
			<span className="flex justify-center">
				<span className="inline-block pt-0.5 mr-4">{children}</span>
				<Icon className="w-7" type={<RightArrow />} />
			</span>
		</Link>

		: type === 'disclaimer' ?
		<Link 
			to={link} 
			className={
				'inline-block border border-blue rounded-3xl px-5 py-2.5 mr-2 last:mr-0 text-white bg-blue font-bold font-sans text-sm hover:bg-bluepurple hover:border-bluepurple active:bg-white active:text-blue active:border-blue ' + className
			} 
		>
			{children}
		</Link>

		: 
    <Link 
			to={link} 
			className={
				'inline-block bg-altblue rounded-3xl w-60 px-4 py-2.5 mr-2 last:mr-0 text-white font-bold font-sans text-base hover:bg-altblue-hover blue active:bg-altblue-active ' + className
			} 
		>
			<span className="flex justify-center">
				<span className="inline-block pt-0.5">{children}</span>
				<Icon className="ml-4" type={<RightArrow />} />
			</span>
		</Link>
	);
};

export default ButtonLink;