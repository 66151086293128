import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";

const InfoTile = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const infoCards = props.data.info_cards && props.data.info_cards.length > 0 && props.data.info_cards.map( (card: any, index: any) => 
    (
      <div key={index} className={'md:ml-20 first:ml-0 animate slide-from-top ease-in-out delay-'+((index+1)*250)}>
        <strong className="text-bold text-2xl md:text-3xl font-bold inline-block relative md:mb-1">
          <span className="inline-block relative z-2">{ card.title }</span>
          <span className="bg-green w-full h-3 absolute bottom-0.5 md:bottom-0 left-0 md:left-2.5" />
        </strong>
        <em className="font-bold text-sm md:text-lg not-italic block mb-4 md:mb-10">{ card.subtitle }</em>
        <p className="text-xs leading-5 md:leading-normal md:text-sm font-medium mb-8 md:mb-10">{ card.description }</p>
      </div>
    )
  );

  return (
    <section ref={sectionEl} className="bg-beige pt-15 pb-7 md:pt-30 md:pb-30 content__info-tile">
      <div className="text-blue mx-auto w-full max-w-4xl px-12 lg:px-1.5">
        <strong className="animate slide-from-left ease-in-out text-sm mb-2 md:text-lg md:mb-0">{ props.data.subheading }</strong>
        <h2 className="animate slide-from-left ease-in-out font-bold text-2xl mb-8 md:text-3xl md:mb-11">
          { props.data.heading }
        </h2>
        <div className="md:flex">
          { infoCards }
        </div>
      </div>
    </section>
  );
}

export default InfoTile;