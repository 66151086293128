import React from "react";

interface IProps {
  color?: string;
}

const IconCheckmarkCircle: React.FC<IProps> = ({
  color = '#83f1aa'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
      <g id="CheckCircle" transform="translate(-774.571 -611)">
        <path id="CheckCircle-2" data-name="Check" d="M19.117,43.655,8.525,54.247a.808.808,0,0,1-1.144,0l-4.2-4.2a1.866,1.866,0,0,0-2.639,2.64L6.265,58.41a2.386,2.386,0,0,0,3.375,0L21.756,46.294a1.866,1.866,0,0,0-2.639-2.639" transform="translate(793.42 589.891)" fill={color}/>
        <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(774.571 611)" fill="none" stroke={color} strokeWidth="1">
          <circle cx="30" cy="30" r="30" stroke="none"/>
          <circle cx="30" cy="30" r="29.5" fill="none"/>
        </g>
      </g>
    </svg>
  );
};

export default IconCheckmarkCircle;
