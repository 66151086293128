import React from "react";

interface IProps {
  color?: string;
}

const IconPhone: React.FC<IProps> = ({
  color = '#83f1aa'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23.86" height="32" viewBox="0 0 23.86 32">
      <g id="Group_23" data-name="Group 23">
        <path id="Path_52" data-name="Path 52" d="M22.915,3.913A4.524,4.524,0,0,0,21.25,1.935Q19.867,1.1,18.462.282A2.244,2.244,0,0,0,17.512,0a2.052,2.052,0,0,0-1.936,1.108L12.109,7.023a2.04,2.04,0,0,0,.761,2.926c.849.508,1.7,1.005,2.559,1.5a1.846,1.846,0,0,1,.259.175.922.922,0,0,1-.012.3,2.056,2.056,0,0,1-.1.469l-.267.677a14.9,14.9,0,0,1-.78,1.774c-1.011,1.826-2.045,3.585-3.073,5.228a8.622,8.622,0,0,1-1.382,1.573c-.222.211-.247.24-.638.012l-.835-.5c-.58-.344-1.158-.688-1.743-1.023a2.032,2.032,0,0,0-2.89.716q-1.84,3.1-3.655,6.221a2.024,2.024,0,0,0,.78,2.876l.618.367c.666.394,1.331.787,2,1.162a3.783,3.783,0,0,0,1.205.421A7.22,7.22,0,0,0,6.1,32a8.8,8.8,0,0,0,3.379-.729,16.145,16.145,0,0,0,5.844-4.35A38.128,38.128,0,0,0,22.41,15.375a18.777,18.777,0,0,0,1.451-6.94v0a9.692,9.692,0,0,0-.946-4.518M20.727,14.707a36.356,36.356,0,0,1-6.745,11A14.412,14.412,0,0,1,8.79,29.6a6.472,6.472,0,0,1-3.574.519,1.967,1.967,0,0,1-.621-.219c-.661-.366-1.312-.752-1.962-1.137l-.622-.368c-.263-.154-.224-.25-.135-.405q1.809-3.109,3.647-6.207c.053-.09.1-.16.188-.16a.523.523,0,0,1,.243.089c.577.331,1.148.67,1.719,1.011l.841.5a2.118,2.118,0,0,0,2.806-.259,10.17,10.17,0,0,0,1.669-1.925c1.046-1.67,2.1-3.457,3.123-5.312A16.3,16.3,0,0,0,17,13.727l.256-.646a3.649,3.649,0,0,0,.218-.908,2.009,2.009,0,0,0-1.13-2.287c-.849-.494-1.7-.987-2.538-1.49-.258-.156-.26-.234-.127-.459l3.465-5.911c.13-.224.2-.224.258-.215a.426.426,0,0,1,.161.041q1.386.8,2.754,1.634A2.657,2.657,0,0,1,21.283,4.7a7.9,7.9,0,0,1,.767,3.733,17,17,0,0,1-1.323,6.273" fill={color}/>
      </g>
    </svg>
  );
};

export default IconPhone;
