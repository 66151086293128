import React from "react";

interface IProps {
  color?: string;
}

const IconLinkedIn: React.FC<IProps> = ({
  color = '#0f204c'
}) => {
  return (
    <svg id="LinkedIn_Logo" data-name="LinkedIn Logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32.1" height="32" viewBox="0 0 32.1 32">
      <g id="Group_5" data-name="Group 5">
        <path id="Path_28" data-name="Path 28" d="M29.6,0H2.4A2.349,2.349,0,0,0,0,2.3V29.7A2.349,2.349,0,0,0,2.4,32H29.7a2.349,2.349,0,0,0,2.4-2.3V2.3A2.506,2.506,0,0,0,29.6,0M4.9,26.8V12.3H9.7V26.7H4.9ZM7.3,10.4h0A2.472,2.472,0,0,1,4.6,7.9,2.528,2.528,0,0,1,7.3,5.4a2.507,2.507,0,1,1,0,5M27.1,26.8H22.3V19.1c0-1.9-.7-3.3-2.4-3.3a2.749,2.749,0,0,0-2.5,1.8,3.732,3.732,0,0,0-.2,1.2v8.1H12.4s.1-13.1,0-14.4h4.8v2a4.763,4.763,0,0,1,4.4-2.4c3.2,0,5.6,2.1,5.6,6.5v8.2Z" transform="translate(0 0.001)" fill={color}/>
      </g>
    </svg>
  );
};

export default IconLinkedIn;
