import React from "react";

interface IProps {
  color?: string;
}

const IconFacebook: React.FC<IProps> = ({
  color = '#0f204c'
}) => {
  return (
    <svg id="FB_Logo" data-name="FB Logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14.933" height="32" viewBox="0 0 14.933 32">
      <g id="Group_9" data-name="Group 9">
        <path id="Path_30" data-name="Path 30" d="M10.667,6.4h4.267V0H10.021C3.065,0,3.229,5.608,3.229,6.444v4.222H0v6.4H3.229V32H9.6V17.066h4.267l1.067-6.4H9.6v-3.2A.966.966,0,0,1,10.667,6.4" fill={color}/>
      </g>
    </svg>
  );
};

export default IconFacebook;
