import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";

const CalloutText = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section ref={sectionEl} className="bg-blue py-20 md:py-32 content__callout-text">
      <div className="animate fade-in-grow ease-in-out w-full max-w-5xl mx-auto px-12 md:px-9">
        <div className="font-display italic text-green text-xl leading-9 md:text-4xl md:leading-normal text-center relative">
          { props.data.content }
        </div>
      </div>
    </section>
  );
}

export default CalloutText;