import React from "react";

interface IProps {
  color?: string;
}

const IconCheckmark: React.FC<IProps> = ({
  color = '#000000'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31.113" height="31.113" viewBox="0 0 31.113 31.113">
      <g id="Group_75" data-name="Group 75" transform="translate(-1228.887 -239)">
        <rect id="Rectangle_153" data-name="Rectangle 153" width="4" height="40" rx="2" transform="translate(1257.172 239) rotate(45)" fill={color} />
        <rect id="Rectangle_154" data-name="Rectangle 154" width="4" height="40" rx="2" transform="translate(1260 267.284) rotate(135)" fill={color} />
      </g>
    </svg>
  );
};

export default IconCheckmark;
