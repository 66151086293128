import React from "react";

interface IProps {
  color?: string;
}

const IconCheckmark: React.FC<IProps> = ({
  color = '#43d18a'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.302" height="16" viewBox="0 0 22.302 16">
      <path id="Check" d="M19.117,43.655,8.525,54.247a.808.808,0,0,1-1.144,0l-4.2-4.2a1.866,1.866,0,0,0-2.639,2.64L6.265,58.41a2.386,2.386,0,0,0,3.375,0L21.756,46.294a1.866,1.866,0,0,0-2.639-2.639" transform="translate(0 -43.109)" fill={color} />
    </svg>
  );
};

export default IconCheckmark;
