import { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { onLoadScrollHandler } from "../../util/helpers";
import GlobalContext from "../../store/GlobalContext";
import Icon, { Phone, Mail, MapPin } from "../Icons";
import Button from "../Button/Button";
import Input from "../Form/Input";
import Checkbox from "../Form/Checkbox";
import Textarea from "../Form/Textarea";

const apiUrl = process.env.REACT_APP_API_URL;

const initialFormValues = {
  fullname: '',
  email: '',
  phone: '',
  message: '',
  brokerageName: '',
  brokerageLicense: '',
  agentLicense: '',
  optin: false,
  callback: false,
  // formSubmitted: false,
  // success: false
}

const ContactForm = (props: any) => {
  const globalCtx = useContext(GlobalContext);
  const contact = globalCtx.global.contact_form;
  const navigate = useNavigate();

  const [errors, setErrors] = useState(false);
  // const [formSubmitted, setFormSubmitted] = useState(false);

  const sectionEl = useRef<HTMLElement>(null);
  const formEl = useRef<HTMLFormElement>(null);
  const recaptchaRef = useRef(null) as any;

  const [formValues, setFormValues] = useState(initialFormValues);
  const [responseMsg, setResponseMsg] = useState('');
  const [responseError, setResponseError] = useState(false);
  const [captchaCompleted, setCaptchaCompleted] = useState(false);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    recaptchaRef.current?.reset();
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const validateForm = () => {
    // return new Promise(function(resolve, reject) {
      if( !formValues.fullname || !formValues.email || !formValues.phone || !formValues.optin || !captchaCompleted ) {
        setErrors(true);
        return false;
      }
      else {
        setErrors(false);
        return true;
        // submitForm();
      }

      // resolve(true);
    // });
  }

  const handleInputValues = () => {
    setFormValues({
      fullname: formEl.current?.fullname.value,
      email: formEl.current?.email.value,
      phone: formEl.current?.phone.value,
      message: formEl.current?.message?.value,
      brokerageName: formEl.current?.brokerageName?.value,
      brokerageLicense: formEl.current?.brokerageLicense?.value,
      agentLicense: formEl.current?.agentLicense?.value,
      optin: formEl.current?.optin.checked,
      callback: formEl.current?.callback.checked,
    });

    validateForm();
  }

  const handleRecaptcha = (value: any) => {
    // console.log("Captcha value:", value);
    setCaptchaCompleted(true);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // setErrors(true);
    // setFormSubmitted(true);

    // validateForm().then(() => {
    //   submitForm();
    // });
    let isFormValid = validateForm();
    if( isFormValid ) {
      submitForm();
    }
  }

  const submitForm = () => {
    // setErrors(false);
    // validateForm();

    // if( !errors && formSubmitted ) {

      // axios.post(`${apiUrl}/send-email`, formValues)
      axios.post(`${apiUrl}/contact`, formValues)
      .then(function (response) {
        if( response.data.status === 'success' ) {
          /* setErrors(false);
          setResponseMsg(response.data.message);
          setResponseError(false);

          setFormValues(initialFormValues);
          formEl.current!.optin.checked = false;
          formEl.current?.reset();

          setTimeout(() => {
            setResponseMsg('');
          }, 3000); */
          navigate('/thank-you');
        }
        else {
          setErrors(true);
          setResponseError(true);
          setResponseMsg(response.data.message);
          setTimeout(() => {
            setResponseMsg('');
          }, 3000);
        }

        // console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setErrors(false);
        setResponseError(true);
        setResponseMsg(error.data.message);
      });

      // console.log('submitted', formValues);
      // setFormSubmitted(false);
      // formEl.current?.reset();

    // }
    // else {
    //   console.log('not valid!');
    // }
  }

  /* const checkboxHandler = (e: any) => {
    console.log(e.target.checked);
  } */

  return (
    <section ref={sectionEl} className={'py-12 md:py-30 content__contact-form' + (props.data.style === 'outlined' ? ' md:bg-beige' : '')}>
      <div className="mx-auto w-full max-w-6xl text-blue px-4.5 text-center lg:text-left lg:grid lg:grid-cols-2 min1200px:px-0">
        <div className="animate slide-from-left ease-in-out lg:mr-26">
          <div className="mb-12">
            <span className="block text-xs md:text-lg font-bold">{ contact.subtitle }</span>
            <h2 className="text-2xl md:text-3.5xl font-semibold mb-2 md:mb-0">{ contact.title }</h2>
            <p className="text-xs md:text-sm font-medium">{ contact.description }</p>
          </div>
          <div className="font-bold text-base md:text-lg leading-relaxed text-left max-w-fit mx-auto lg:mx-0 lg:max-w-none">
            <p className="flex mb-4">
              <span className="inline-block w-11 text-center">
                <Icon type={<Phone />} />
              </span>
              <span className="ml-5">{ contact.phone }</span>
            </p>
            <p className="flex mb-4">
              <span className="inline-block w-11 text-center">
                <Icon type={<Mail />} />
              </span>
              <span className="ml-5">{ contact.email }</span>
            </p>
            <p className="flex mb-4">
              <span className="inline-block w-11 text-center">
                <Icon type={<MapPin />} />
              </span>
              <span dangerouslySetInnerHTML={{ __html: contact.address }} className="block ml-5" />
            </p>
          </div>
        </div>
        <div
          className={
            'animate slide-from-right ease-in-out mt-12 px-7 md:mt-0 min1514px:min-w-fit' +
            (props.data.style === 'outlined' ? ' md:bg-white md:pt-6 md:pb-10 md:px-8 md:border md:border-blue md:rounded-20px' : '')
          }
        >
          <form ref={formEl} className="contact-form" onSubmit={handleSubmit}>
            <div className="mb-4">
              <Input
                type="text"
                placeholder="First and Last Name"
                name="fullname"
                isRequired={true} 
                onChange={handleInputValues} 
                onBlur={handleInputValues} 
              />
              {
                errors && !formValues.fullname && 
                <p className="text-red text-xs font-medium leading-tight mt-1.5 text-left">Please enter your full name</p>
              }
            </div>
            <div className="mb-4">
              <Input
                type="email"
                placeholder="Email Address"
                name="email"
                isRequired={true}
                onChange={handleInputValues} 
                onBlur={handleInputValues} 
              />
              {
                errors && !formValues.email && 
                <p className="text-red text-xs font-medium leading-tight mt-1.5 text-left">Please enter your email address</p>
              }
            </div>
            <div className="mb-4">
              <Input
                type="text"
                name="phone"
                placeholder="Phone Number"
                isRequired={true}
                onChange={handleInputValues} 
                onBlur={handleInputValues} 
              />
              {
                errors && !formValues.phone && 
                <p className="text-red text-xs font-medium leading-tight mt-1.5 text-left">Please enter your phone number</p>
              }
            </div>
            {!props.data.show_broker_fields && (
              <div className="mb-4">
                <Textarea
                  placeholder="Message"
                  rows={1}
                  name="message"
                  required={false} 
                  onChange={handleInputValues} 
                  onBlur={handleInputValues} 
                />
              </div>
            )}
            {props.data.show_broker_fields && (
              <>
                <div className="mb-4">
                  <Input
                    type="text"
                    name="brokerageName"
                    placeholder="Brokerage Name"
                    isRequired={false}
                    onChange={handleInputValues} 
                    onBlur={handleInputValues} 
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="text"
                    name="brokerageLicense"
                    placeholder="Brokerage License #"
                    isRequired={false}
                    onChange={handleInputValues} 
                    onBlur={handleInputValues} 
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="text"
                    name="agentLicense"
                    placeholder="Agent License #"
                    isRequired={false}
                    onChange={handleInputValues} 
                    onBlur={handleInputValues} 
                  />
                </div>
              </>
            )}
            <div className="mb-4">
              <Checkbox 
                name="callback" 
                value="Call back requested" 
                text="Request a call back" 
                // required={true} 
                onChange={handleInputValues} 
                onBlur={handleInputValues} 
              />
            </div>
            <div className="mb-4">
              <Checkbox 
                name="optin" 
                value="Opt in" 
                text="*By checking this box, you agree to send your information to Abbey Ridge MIC" 
                // required={true} 
                onChange={handleInputValues} 
                onBlur={handleInputValues} 
              />
              {
                errors && !formValues.optin && 
                <p className="text-red text-xs font-medium leading-tight mt-1.5 text-left">Please agree to our terms</p>
              }
            </div>
            <div className="pt-4">
              <ReCAPTCHA
                onChange={handleRecaptcha}
                ref={recaptchaRef}
                // @ts-ignore
                sitekey={process.env.REACT_APP_SITE_KEY}
              />
              {
                !captchaCompleted && errors && 
                <p className="text-red text-xs font-medium leading-tight mt-1.5 text-left">Please verify that you're not a robot</p>
              }
            </div>
            <div className="text-left md:text-right">
              {/* <Button className={'mt-9 '+(errors ? 'opacity-50' : '')} type="submit">Submit</Button> */}
              <Button className="mt-4 md:mt-9" type="submit">Submit</Button>
            </div>
          </form>
          {
            responseMsg && 
            <p className={(responseError ? 'text-red ' : 'text-green-active ')+'text-base font-semibold'}>{responseMsg}</p>
          }
        </div>
      </div>
    </section>
  );
}

export default ContactForm;