import React from "react";

interface IProps {
  color?: string;
}

const IconRightArrow: React.FC<IProps> = ({
  color = '#83f1aa'
}) => {
  return (
    <svg id="Arrow" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="Group_2" data-name="Group 2">
        <path id="Path_15" data-name="Path 15" d="M14,28A14,14,0,1,1,28,14,14.016,14.016,0,0,1,14,28M14,1.058A12.942,12.942,0,1,0,26.941,14,12.956,12.956,0,0,0,14,1.058" transform="translate(0 0)" fill={color}/>
        <path id="Path_16" data-name="Path 16" d="M20.056,12.895l-1.784,1.784,3.484,3.484H8.913v2.523H21.756l-3.484,3.484,1.784,1.784,6.529-6.529Z" transform="translate(-3.749 -5.424)" fill={color}/>
      </g>
    </svg>
  );
};

export default IconRightArrow;
