import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";

const ContentTile = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  /* const onLoadScrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      sectionEl.current?.classList.add('on-screen');
    }
  } */

  useEffect(() => {
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const contentBlock = props.data.blocks && props.data.blocks.length > 0 && props.data.blocks.map( (block: any, index: any) => 
    (
      block.block_type === 'big_image' ?
        <div key={index} className="mx-auto w-full max-w-7xl pt-9 pb-11 px-12 md:px-10 md:pt-22 md:pb-28 font-sans text-center">
          <span>{ props.data.sub_heading }</span>
          <h1 className="animate slide-from-top ease-in-out text-green text-2xl leading-tight md:text-4xl font-bold mx-auto max-w-2xl md:leading-normal pb-4">{ props.data.heading }</h1>
          <p className="animate slide-from-bottom ease-in-out font-bold text-white text-sm md:text-lg pb-12 md:pb-24 mx-auto max-w-4xl">{ props.data.description }</p>
          <div className="animate slide-from-bottom ease-in-out h-[17.5rem] md:h-171 overflow-hidden hidden md:block" style={{
            background: `url('${block.block_content[0]}') ${block.image_alignment} / ${block.background_size} no-repeat`,
          }}>
            <img className="opacity-0" src={block.block_content[0]} alt="" />
          </div>
          <div className="h-[17.5rem] overflow-hidden md:hidden" style={{
            background: `url('${block.block_content[0]}') ${block.image_alignment} / cover no-repeat`,
          }}>
            <img className="opacity-0" src={block.block_content[0]} alt="" />
          </div>
        </div>

		  : block.block_type === 'cards' ?
        <div key={index} className="mx-auto w-full max-w-[89.875rem] pt-16 pb-16 lg:pt-22 lg:pb-28 font-sans text-left">
          <div className="text-center px-12 md:px-0">
            <span className="font-bold text-white text-sm md:text-lg">{ props.data.sub_heading }</span>
            <h2 className="text-green text-2xl md:text-4xl font-bold mx-auto max-w-2xl leading-normal pb-4">{ props.data.heading }</h2>
            <p className="text-white text-xs leading-5 md:text-sm md:leading-6 mx-auto mb-36 md:mb-64 lg:mb-64 max-w-4xl px-4">{ props.data.description }</p>
          </div>
          <div className="font-sans text-blue px-12 lg:flex lg:justify-center lg:items-end">
          { block && block.block_content.length > 0 && block.block_content.map( (card: any, index: any) => (
            <div key={index} className={'animate slide-from-top ease-in-out delay-'+((index+1)*250)+' bg-white px-10 pt-[1px] group mx-auto even:bg-green mt-36 md:mt-64 lg:mt-0 max-w-70 md:max-w-110 lg:pt-0 lg:mx-0 lg:px-11 lg:even:basis-1/3 lg:even:max-w-110 lg:odd:basis-3/10 lg:odd:min-h-card lg:odd:max-w-97.5 lg:ml-10 lg:first:ml-0'}>
              {/* <div className="w-full h-45 -mt-22 md:h-75 md:group-odd:-mt-36 md:group-even:w-full md:group-even:h-87.5 md:group-even:-mt-44" style={{
                background: `url('${card.image[0]}') center no-repeat`,
                backgroundSize: 'cover'
              }}> */}
              <div className="w-full pb-[100%] -mt-[50%] overflow-hidden" style={{
                background: `url('${card.image[0]}') center no-repeat`,
                backgroundSize: 'cover'
              }}>
                <img className="hidden" src={card.image[0]} alt="" />
              </div>
              <h3 className="pt-8 text-lg md:text-2xl font-bold">{ card.title }</h3>
              <p className="font-medium text-xs leading-5 pt-2 pb-10 lg:text-sm lg:leading-6 lg:group-odd:pb-9 lg:group-even:pb-24">{ card.description }</p>
            </div>
          )) }
          </div>
        </div>

		  : block.block_type === 'text_block' ?
        <div key={index} className="mx-auto w-full max-w-7xl px-10 pt-10 pb-10 md:pt-22 md:pb-28 font-sans text-center">
          <span>{ props.data.sub_heading }</span>
          <h1 className="animate slide-from-top ease-in-out text-green text-2xl leading-tight md:text-4xl font-bold mx-auto max-w-2xl md:leading-normal pb-4">{ props.data.heading }</h1>
          <p className="animate slide-from-bottom ease-in-out font-bold text-white text-sm md:text-lg pb-12 md:pb-24 mx-auto max-w-4xl">{ props.data.description }</p>
          <div className="px-2 text-left md:grid md:grid-cols-2 lg:px-44 block__text-block">
            <div dangerouslySetInnerHTML={{ __html: block.block_content.column_1 }} className="animate slide-from-left ease-in-out content mb-10 md:mb-0 md:mr-10" />
            <div dangerouslySetInnerHTML={{ __html: block.block_content.column_2 }} className="animate slide-from-right ease-in-out content" />
          </div>
        </div>
      : null
    )
  );

  return (
    <section ref={sectionEl} className="bg-blue content__content-tile">
      { contentBlock }
    </section>
  );
}

export default ContentTile;