import { useEffect, useRef } from "react";
// import { onLoadScrollHandler } from "../../util/helpers";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const MixedCopy = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  /* const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  } */

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      start: "top-=200px center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });

    /* // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler); */
  }, []);

  const copy = props.data && props.data.copy && props.data.copy.length > 0 && props.data.copy.map( (content: any, index: any) => 
    (
      content.copy_type === 'blockquote' ?
        <blockquote key={index} className={'animate slide-from-left ease-in-out delay-'+((index+1)*250)+' left mb-10'}>
          <p>{ content.content }</p>
        </blockquote>

      : content.copy_type === 'full_width_column' ? 
        <div key={index} className={'animate slide-from-left ease-in-out delay-'+((index+1)*250)+' left text-sm font-medium text-blue'} dangerouslySetInnerHTML={{ __html: content.content }} />

		  : content.copy_type === 'two_column' ?
        <div key={index} className={'animate slide-from-left ease-in-out delay-'+((index+1)*250)+' text-xs md:text-sm font-medium mb-3 md:px-10 text-blue md:flex two-col'}>
          <div className="md:basis-2/4 md:mr-10" dangerouslySetInnerHTML={{ __html: content.content.column_1 }} />
          <div className="md:basis-2/4" dangerouslySetInnerHTML={{ __html: content.content.column_2 }} />
        </div>

      : null
    )
  );

  return (
    <section ref={sectionEl} className="content__mixed-copy">
      <div className="mx-auto max-w-5xl px-12 pt-14 md:pt-32">
        { copy }
      </div>
    </section>
  );
}

export default MixedCopy;