import React from "react";

interface IProps {
  color?: string;
}

const IconRightArrowNoBorder: React.FC<IProps> = ({
  color = '#83f1aa'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32.346" height="18" viewBox="0 0 32.346 18">
      <path d="M23.345,0l-2.46,2.459,4.8,4.8H0v3.477H25.689l-4.8,4.8L23.345,18l9-9Z" transform="translate(0)" fill={color}/>
    </svg>
  );
};

export default IconRightArrowNoBorder;
