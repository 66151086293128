import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";

const IconsTile = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const iconsGroup = props.data.icons_group && props.data.icons_group.length > 0 && props.data.icons_group.map( (icon: any, index: any) => 
    (
      <div key={index} className={'animate fade-in-grow ease-in-out delay-'+((index+1)*250)+' text-center mb-10 last:mb-0 md:mb-0 md:ml-20 md:first:ml-0'}>
        <div className="h-14 text-center">
          <img className="w-auto h-full inline-block" src={icon.icon[0]} alt="" />
        </div>
        <strong className="text-green font-bold text-lg md:text-2xl leading-10 block my-2">{ icon.title }</strong>
        <p className="text-white font-medium text-xs leading-5 md:text-sm md:leading-6">{ icon.description }</p>
      </div>
    )
  );

  return (
    <section ref={sectionEl} className="bg-blue pt-10 pb-14 md:pt-20 md:pb-52 content__icons-tile">
      <div className="w-full mx-auto max-w-6xl px-12 md:px-4 font-sans md:flex">
          { iconsGroup }
      </div>
    </section>
  );
}

export default IconsTile;