import { useContext } from "react";
// import { Link } from "react-router-dom";
import GlobalContext from "../store/GlobalContext";
import Navigation from "./Navigation";
import MobileNav from "./MobileNav";

const HeaderAlt = () => {
  const globalCtx = useContext(GlobalContext);
  const header = globalCtx.global.header;

  return (
    <header className="site-header h-auto px-6 pt-5 pb-5 fixed top-0 left-0 w-screen z-50 bg-white min1186px:bg-[transparent] min1186px:relative min1186px:mx-0 min1186px:w-screen min1186px:pt-0 min1186px:pb-0 min1514px:px-12">
      <div className="w-[calc(100vw-48px)] h-[1px] absolute top-19 left-0 mx-6 bg-blue min1186px:hidden" />
      <div className="hidden min1186px:block">
        <Navigation data={header} altHeader={true} />
      </div>
      <div className="min1186px:hidden">
        <MobileNav data={header} />
      </div>
    </header>
  )
}

export default HeaderAlt;