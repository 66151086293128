import { useEffect, useRef } from "react";
import ButtonLink from "../../Button/ButtonLink";
import Icon, { CheckmarkCircle } from "../../Icons";
import Stats from "./Stats";
// import { onLoadScrollHandler } from "../../util/helpers";
// import bgLines from '../../img/bg-lines_2x.png';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const HeroSectionWithStats = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  /* const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  } */

  useEffect(() => {
    /* gsap.fromTo(
      sectionEl.current,
      {
        // opacity: 0,
        // y: 250
      },
      {
        // opacity: 1,
        // y: 0,
        scrollTrigger: {
          trigger: sectionEl.current,
          start: "top center",
          // start: "center center",
          end: "bottom top",
          // scrub: true
          toggleClass: "on-screen",
          // toggleActions: "play reverse none none",
          // markers: true,
          once: true,
        }
      }
    ); */

    ScrollTrigger.create({
      trigger: sectionEl.current,
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });

    // scrollHandler();
    /* onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler); */
  }, []);

  const listGroup = props.data.list_group && props.data.list_group.length > 0 && props.data.list_group.map( (listItem: any, index: any) => 
    (
      <li key={index} className={'text-base font-semibold text-center mb-8 last:mb-0 md:mb-0 animate slide-from-top ease-in-out' + (index === 0 ? ' delay-1000' : index === 1 ? ' delay-1250' : ' delay-1500' )}>
        <Icon className="w-15 h-15" type={<CheckmarkCircle />} />
        <span className="block pt-0.5 max-w-[169px] mx-auto">{ listItem }</span>
      </li>
    )
  );

  /* const wrapEachWord = (str: string) => {
    return str.replace(/(^|<\/?[^>]+>|\s+)([^\s<]+)/g, '$1<span class="word"><em>$2</em></span>');
  } */
  
  return (
    <>
    <section ref={sectionEl} className="content__hero-section-with-stats w-screen h-auto md:h-[56.25vw] max-h-[1080px] pb-15 pt-15 relative lg:pt-30 min1186px:-mt-[142px] bg-beige min1186px:pt-44 min1186px:min-h-[1080px] lg:min-h-[960px] md:min-h-[720px] md:pb-24">
      <div className="video-bg w-full h-full overflow-hidden absolute top-0 left-0">
        <video className="w-auto h-full object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" width="1920" height="1080" autoPlay muted loop playsInline>
          <source src={props.data.video_background.mp4_url} type="video/mp4" />
          <source src={props.data.video_background.webm_url} type="video/webm" />
          <source src={props.data.video_background.ogv_url} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="max-w-9xl mx-auto items-center justify-center md:px-17 lg:flex min1200px:pt-24 min1200px:px-26 min1200px:items-start">
        <div className="mx-auto px-12 md:mx-0 md:px-0">
          <h1 className="animate slide-from-left ease-in-out delay-300 text-green font-sans font-bold text-4xl leading-none relative mx-auto text-center max-w-4xl px-14 mb-2 md:text-7xl">
            {props.data.heading}
          </h1>
          <div className="animate slide-from-right ease-in-out delay-700 text-white relative text-2xl font-medium max-w-4xl mx-auto text-center md:text-3.5xl md:leading-normal" dangerouslySetInnerHTML={{ __html: props.data.description }} />
          <div>
            <ul className="font-sans text-sm font-medium text-white leading-18px pt-8 pb-8 mx-auto max-w-md md:flex md:justify-center md:gap-15 relative lg:max-w-none lg:mx-0 min1200px:leading-5 min1200px:pt-8 min1200px:pb-9 min1200px:text-lg">
              { listGroup }
            </ul>
            <p className="animate slide-from-left ease-in-out delay-2000 text-white text-sm font-semibold relative text-center mb-10 md:mb-17">{ props.data.note }</p>
            <div className="animate slide-from-left ease-in-out delay-2000 text-center relative z-10 md:mb-30 lg:mb-36 lg:text-left min1186px:mb-0">
              <ButtonLink type="large" link={'/'+props.data.cta.url.post_name} className="mx-auto">
                { props.data.cta.text }
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
      <div className="min1186px:absolute min1186px:left-1/2 min1186px:bottom-0 min1186px:transform min1186px:-translate-x-1/2 min1186px:translate-y-1/2 z-50 w-full max-w-[1566px] mx-auto hidden lg:block">
        <Stats data={props.data.stats} />
      </div>
    </section>
    <div className="bg-beige h-60 hidden lg:block"></div>
    <div className="block lg:hidden">
        <Stats data={props.data.stats} />
      </div>
    </>
  );
}

export default HeroSectionWithStats;