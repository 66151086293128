import { useEffect, useState, useContext } from "react";
import axios from "axios";

import GlobalContext from "../store/GlobalContext";
import Header from "../components/Header";
import HeaderAlt from "../components/HeaderAlt";
import Footer from "../components/Footer";
import NewsletterPopup from "../components/NewsletterPopup";

import BigQuote from "../components/Content/BigQuote";
import ContentTile from "../components/Content/ContentTile";
import CalloutText from "../components/Content/CalloutText";
import ContactForm from "../components/Content/ContactForm";
import CtaTile from "../components/Content/CtaTile";
import Disclaimer from "../components/Content/Disclaimer";
import FullWidthTwoCol from "../components/Content/FullWidthTwoCol";
import GetInTouchTile from "../components/Content/GetInTouchTile";
import HeroSection from "../components/Content/HeroSection";
import HeroSectionWithStats from "../components/Content/HeroSectionWithStats";
import IconsTile from "../components/Content/IconsTile";
import ImageGroupWithText from "../components/Content/ImageGroupWithText";
import InfoTile from "../components/Content/InfoTile";
import LeftImageWithCta from "../components/Content/LeftImageWithCta";
import LeftImageWithCta2 from "../components/Content/LeftImageWithCta2";
import LeftImageWithText from "../components/Content/LeftImageWithText";
import ListGroup from "../components/Content/ListGroup";
import ListWithImage from "../components/Content/ListWithImage";
import MixedCopy from "../components/Content/MixedCopy";
import NewsletterTile from "../components/Content/NewsletterTile";
import StaticCopy from "../components/Content/StaticCopy";
import StatsTile from "../components/Content/StatsTile";
import Steps from "../components/Content/Steps";
import TwoColCta from "../components/Content/TwoColCta";
import TwoColFacts from "../components/Content/TwoColFacts";
import TwoColMixed from "../components/Content/TwoColMixed";

const Page = (props: any) => {
  const globalCtx = useContext(GlobalContext);
  const [pageData, setPageData] = useState() as any;
  const [showNewsletterPopup, setNewsletterPopup] = useState(false);

  useEffect(() => {
    axios.get(`${globalCtx.apiUrl}/pages/single?slug=${props.slug}`)
    .then( response =>  {
      setPageData(response.data);
      document.title = `Abbey Ridge MIC - ${props.title}`;

      if (
        (localStorage.getItem('abbeyridgeNewsletterPopup') === null || 
        localStorage.getItem('abbeyridgeNewsletterPopup') === '1') &&
        sessionStorage.getItem('abbeyridgeNewsletterPopup') === null
      ) {
        setNewsletterPopup(true);
      }

      window.scrollTo(0, 0);
    })
    .catch( error => {
      console.error(error);
    });
    // eslint-disable-next-line
  }, [props.slug]);

  const pageContent = pageData && pageData.page_content && pageData.page_content.length > 0 && pageData.page_content.map( (content: any, index: any) => 
    (
      content.content_type === 'hero_section' ?
        <HeroSection key={content.field_id} data={content} />
      : content.content_type === 'hero_section_with_stats' ?
        <HeroSectionWithStats key={content.field_id} data={content} />
		  : content.content_type === 'content_tile' ?
        <ContentTile key={content.field_id} data={content} />
		  : content.content_type === 'full_width_2_col' ?
        <FullWidthTwoCol key={content.field_id} data={content} />
		  : content.content_type === 'get_in_touch_tile' ?
        <GetInTouchTile key={content.field_id} data={content} />
		  : content.content_type === 'newsletter_tile' ?
        <NewsletterTile key={content.field_id} data={content} />
		  : content.content_type === 'contact_form' ?
        <ContactForm key={content.field_id} data={content} />
		  : content.content_type === 'two_column_mixed' ?
        <TwoColMixed key={content.field_id} data={content} />
		  : content.content_type === 'left_image_with_cta' ?
        <LeftImageWithCta key={content.field_id} data={content} />
		  : content.content_type === 'left_image_with_cta_2' ?
        <LeftImageWithCta2 key={content.field_id} data={content} />
		  : content.content_type === 'stats_tile' ?
        <StatsTile key={content.field_id} data={content} />
		  : content.content_type === 'cta_tile' ?
        <CtaTile key={content.field_id} data={content} />
		  : content.content_type === 'mixed_copy' ?
        <MixedCopy key={content.field_id} data={content} />
		  : content.content_type === 'left_img_with_text' ?
        <LeftImageWithText key={content.field_id} data={content} />
		  : content.content_type === 'list_group' ?
        <ListGroup key={content.field_id} data={content} />
		  : content.content_type === 'big_quote' ?
        <BigQuote key={content.field_id} data={content} />
		  : content.content_type === 'icons_tile' ?
        <IconsTile key={content.field_id} data={content} />
		  : content.content_type === 'info_tile' ?
        <InfoTile key={content.field_id} data={content} />
		  : content.content_type === 'callout_text' ?
        <CalloutText key={content.field_id} data={content} />
		  : content.content_type === 'two_column_facts' ?
        <TwoColFacts key={content.field_id} data={content} />
		  : content.content_type === 'two_column_cta' ?
        <TwoColCta key={content.field_id} data={content} />
		  : content.content_type === 'list_with_image' ?
        <ListWithImage key={content.field_id} data={content} />
		  : content.content_type === 'img_group_with_text' ?
        <ImageGroupWithText key={content.field_id} data={content} />
		  : content.content_type === 'static_copy' ?
        <StaticCopy key={content.field_id} data={content} />
		  : content.content_type === 'disclaimer' ?
        <Disclaimer key={content.field_id} data={content} />
		  : content.content_type === 'steps' ?
        <Steps key={content.field_id} data={content} />
      : null
    )
  );

  return (
    <div className="bg-white pt-19 min1186px:pt-0">
      {
        showNewsletterPopup &&
        <NewsletterPopup />
      }
      {
      pageData && pageData.use_alt_header ?
        <HeaderAlt />
      :
        <Header />
      }
      { pageContent }
      {
        pageContent && 
        <Footer />
      }
    </div>
  );
}

export default Page;
