import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";

const StaticCopy = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section ref={sectionEl} className="bg-blue content__static-copy">
      <div className="animate fade-in-grow ease-in-out py-12 md:py-30 px-12 mx-auto w-full max-w-4xl">
        <h1 className="text-green font-bold mb-4 text-lg md:text-2xl">{ props.data.title }</h1>
        <div dangerouslySetInnerHTML={{ __html: props.data.content }} className="text-white text-xs md:text-sm font-medium leading-6" />
      </div>
    </section>
  );
}

export default StaticCopy;