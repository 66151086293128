import React from "react";

interface IProps {
	className?: string;
	placeholder?: string;
	name?: string;
	rows?: number;
	// value?: string;
	// disabled?: boolean;
	required?: boolean;
	onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
	onBlur: React.FocusEventHandler<HTMLTextAreaElement>;
}

const Textarea: React.FC<IProps> = ({
	className = '',
	// value,
	// disabled,
	// children,
	placeholder,
	name,
	rows,
	required,
	onChange,
	onBlur,
}) => {
	return (
		<textarea
      placeholder={placeholder} 
      rows={rows} 
      name={name} 
      required={required} 
      className={'block border-b border-blue font-medium text-base leading-5 py-4 mb-4 w-full ' + className} 
			onChange={onChange} 
			onBlur={(e) => onBlur(e)} 
		/>
	);
};

export default Textarea;